import React, { useEffect, useState } from 'react'
import './homepage.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer';
import AboutUsContent from '../Aboutus/AboutUsContent'
import ServicesContent from '../Services/ServicesContent'
import api from '../../../Services/api'
import { Link } from 'react-router-dom';
import Slider from './Slider/Slider';

function Homepage(props) {
  let [language] = useState(localStorage.getItem('language'));
  const [products, setProducts] = useState();

  useEffect(() => {
    const activeElement = document.getElementsByClassName('active')[0];
    activeElement.classList.remove('active');
    document.getElementById('homepagenavItem').classList.add('active');

    const fetchProducts = async () => {
      try {
        const response = await api.get('/api/getProducts'); // API endpoint
        setProducts(response.data); 
      } catch (error) {
        console.error('Error occured calling API', error);
      }
    };

    fetchProducts();
    
  }, [])

  return (
    <div id='homePage' className='homePage' >
      <Header props={props} />
      <Slider/>
      <div className="productsOverview">
        <h1 className="componentHeading">{language === 'en' ? 'Products' : 'Məhsullar'}</h1>

        <div id='homePageproductsContent' className="productsContent">
        {
        (products === undefined)?'':
        Object.values(products).slice(products.length - 3).map((card) => (
          <div id={card.productId} key={card.productId} className="cardProductItem" >
            <Link className='cardPhotoLink' to={`/products/${card.productId}`}><img className='cardPhoto' src={`${process.env.PUBLIC_URL}/assets/img/productGallery/${card.productPhotoPath}`} alt="Product" /></Link>
            <h1 className='cardHeading' >{language === 'en' ? card.productName : card.productName_az}</h1>
            <p className='cardContent' >{language === 'en' ? card.productDetailShort : card.productDetailShort_az}</p>
          </div>
        ))}
          <Link id='linkToAllProducts' to="/products" ><button id='buttonToAllProducts' >{language === 'en' ? "All products" : "Bütün məhsullar"}</button></Link>
        </div>
          
      </div>
      <div className='ServicesContentContainer'>
        <div className="margin"></div>
        <ServicesContent />
      </div>
      <AboutUsContent />
      <Footer />
    </div>
  )
}

export default Homepage