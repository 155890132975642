import React, { useEffect, useState } from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import api from '../../../Services/api'

function Footer() {

    const [contactDataFromBackend, setContactDataFromBackend] = useState({});

    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await api.get('/api/getCustomerDetails'); // API endpoint
                setContactDataFromBackend(response.data);
            } catch (error) {
                console.error('Error occured calling API', error);
            }
        };

        fetchContactData();
    }, []);

    let [language] = useState(localStorage.getItem('language'));
    return (
        <div id='footer' className='Footer' >
            <div className="footerFirstContainer">
                <div className="footerFirstContainerFirstPart">
                    <div className="companyInfo">Bakhtar Azerbaijan</div>
                    <div className="socialMedia">
                        <a rel="noreferrer" target='_blank' href="https://www.instagram.com/"><img className='socialmedialogo' src={`${process.env.PUBLIC_URL}/assets/img/instagramlogo.svg`} alt="Instagram" /></a>
                        <a rel="noreferrer" target='_blank' href="https://web.whatsapp.com/"><img className='socialmedialogo' src={`${process.env.PUBLIC_URL}/assets/img/whatsapplogo.svg`} alt="Whatsapp" /></a>
                    </div>
                </div>
                <div className="footerFirstContainerSecondPart">
                    <Link id='productsnavItem' className='navItem' to="/products" >{language === 'en' ? 'Products' : 'Məhsullar'}</Link>
                    <Link id='servicesnavItem' className='navItem' to="/services" >{language === 'en' ? 'Services' : 'Xidmətlər'}</Link>
                    <Link id='aboutnavItem' className='navItem' to="/about" >{language === 'en' ? 'About Us' : 'Haqqımızda'}</Link>
                    <Link id='contactnavItem' className='navItem' to="/contact" >{language === 'en' ? 'Contact' : 'Əlaqə'}</Link>
                </div>
                <div className="footerFirstContainerThirdPart">
                    <div className="phoneInfo">
                        <img className='phoneImage' src={`${process.env.PUBLIC_URL}/assets/img/phoneImage.svg`} alt="phoneNumberData" />
                        <p>{contactDataFromBackend.phoneNumber}</p>
                    </div>
                    <div className="emailInfo">
                        <img className='emailImage' src={`${process.env.PUBLIC_URL}/assets/img/emailImage.svg`} alt="email" />
                        <p>{contactDataFromBackend.email}</p>
                    </div>
                    <div className="locationInfo">
                        <img className='locationImage' src={`${process.env.PUBLIC_URL}/assets/img/locationImage.svg`} alt="location" />
                        <p>{language === 'en' ? contactDataFromBackend.address : contactDataFromBackend.address_az}</p>
                    </div>
                </div>
            </div>
            <div className="footerSecondContainer">
                © 2024, Bütün hüquqlar qorunur
            </div>
        </div>
    )
}

export default Footer