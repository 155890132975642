import React, { useEffect, useState } from 'react';
import '../AdminAfterAuth.css'; // Stil dosyası
import api from '../../../../Services/api';

function ChangeContactDetails() {


    // Değiştirilebilir alanlar için state'leri tanımlayın
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [addressAz, setAddressAz] = useState('');
    const [error, setError] = useState('');

    // useEffect kancası ile başlangıç değerlerini al
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/api/getCustomerDetails');
                const customerData = response.data;
                // API'den gelen verileri state'lere ayarla
                setPhoneNumber(customerData.phoneNumber);
                setEmail(customerData.email);
                setAddress(customerData.address);
                setAddressAz(customerData.address_az);
            } catch (error) {
                setError('Başlangıç verileri alınamadı.');
                console.error('Başlangıç verileri alınırken bir hata oluştu:', error);
            }
        };

        fetchData();
    }, []); // Boş bağımlılık dizisi, sadece bileşen yüklendiğinde bir kez çalışmasını sağlar

    // Backend API'ye güncelleme isteği gönderen fonksiyon
    const updateUserData = async () => {
        // Adres alanlarındaki verilerin boş olup olmadığını kontrol et
        if (!address || !addressAz) {
            setError('Adres alanları boş bırakılamaz.');
            return;
        }

        try {
            const response = await api.put('api/updateCustomerDetails', {
                phoneNumber,
                email,
                address,
                address_az: addressAz // Adresin Azerice versiyonunu backend tarafında "address_az" olarak kabul edeceğiz
            });
            alert('Success!',response) // Backend'den gelen yanıtı konsola yazdır



        } catch (error) {
            setError('Bir hata oluştu, veriler güncellenemedi.');
            console.error('Backend API ile iletişimde bir hata oluştu:', error); // Hata durumunda konsola hata mesajını yazdır
        }
    }

    return (
        <div className="admin-after-auth-contact">
            <h2>Change Contact Details :</h2>
            {/* Telefon numarası inputu */}
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input type="text" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

            {/* Email inputu */}
            <label htmlFor="email">Email:</label>
            <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />

            {/* Adres inputu */}
            <label htmlFor="address">Address:</label>
            <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />

            {/* Azərbaycan dilindeki adres inputu */}
            <label htmlFor="addressAz">Address (Azərbaycan dilində):</label>
            <input type="text" id="addressAz" value={addressAz} onChange={(e) => setAddressAz(e.target.value)} />

            {/* Güncelleme butonu */}
            <button className='' onClick={updateUserData}>Update User Data</button>

            {/* Hata durumu */}
            {error && <div className="error">{error}</div>}
            {/* Geri dönüş butonu */}

        </div>
    );
}

export default ChangeContactDetails;
