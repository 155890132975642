import React, { useEffect, useState } from 'react';
import './slider.css'

function Slider() {

    const sliderPaths = [
        "slider1.jpg",
        "slider2.jpg",
        "slider3.jpg",
        "slider4.jpg"
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            goToNextSlide();
        }, 5000);

        return () => {
            clearInterval(timer);
        };
    }, [currentIndex]);

    const goToPreviousSlide = () => {
        setCurrentIndex(currentIndex === 0 ? sliderPaths.length - 1 : currentIndex - 1);
    };

    const goToNextSlide = () => {
        setCurrentIndex(currentIndex === sliderPaths.length - 1 ? 0 : currentIndex + 1);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="slider-container">
            <div className="slide">
                <img className='sliderPhoto' src={`${process.env.PUBLIC_URL}/assets/img/sliderGallery/${sliderPaths[currentIndex]}`} alt={`Slide ${currentIndex + 1}`} />
                <button className="prev" onClick={goToPreviousSlide}>{"<"}</button>
                <button className="next" onClick={goToNextSlide}>{">"}</button>
            </div>
            <div className="dots">
                {sliderPaths.map((_, index) => (
                    <span
                        key={index}
                        className={index === currentIndex ? "dot activeSlider" : "dot"}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Slider;
