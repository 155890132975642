import React, { useState } from 'react';
import './changeSliderPhoto.css';
import api from '../../../../Services/api';

function ChangeSliderPhoto() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };
  
  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('sliderPhoto', selectedFile); 
    formData.append('selectedNumber', selectedNumber); // Slider numarasını form data'ya ekle

    api.post('/api/upload', formData)
      .then(response => {
        console.log('File Uploaded:', response.data);
      })
      .catch(error => {
        console.error('Error occured during file uploading:', error);
      });
  };
  
  
  const handleOptionChange = (number) => {
    setSelectedNumber(number);
  };
  
  return (
    <div className="changeSliderPhoto">
      <h2>Change Slider Photo :</h2>
      <div className="options">
        {[1, 2, 3, 4].map((number) => (
          <label key={number}>
            <input
              type="radio"
              value={number}
              checked={selectedNumber === number}
              onChange={() => handleOptionChange(number)} 
            />
            Photo {number}
          </label>
        ))}
      </div>
      <input
        type="file"
        onChange={handleFileChange}
        accept="image/*"
      />
      {fileName && <p>Selected file : {fileName}</p>}
      <button onClick={(e)=> handleUpload(e)}>Upload</button>
    </div>
  );
}

export default ChangeSliderPhoto;
