import React, { useEffect, useState } from 'react'
import api from '../../../../Services/api'
import { Link, useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

function Product() {
    const { id } = useParams();

    const [products, setProducts] = useState();

    let [language] = useState(localStorage.getItem('language'));


    useEffect(() => {
        console.log(id);
        const fetchProducts = async () => {
            try {
                const response = await api.get('/api/getProducts'); // API endpoint
                setProducts(response.data);
            } catch (error) {
                console.error('Error occured calling API', error);
            }
        };

        fetchProducts();
    });

    return (
        <div id='productSingle' >
            <Header />
            <Link  to="/products" ><div  className="turnBack">
                <img src={`${process.env.PUBLIC_URL}/assets/img/back.svg`} alt="" />
                <span id='linktoBack' >{language === 'en' ? 'Back' : 'Geriyə'}</span>
            </div>
            </Link>
            {
                (products === undefined) ? '' :
                    <div className="cardProductItemSingle" >
                        <img className='cardProductPhoto' src={`${process.env.PUBLIC_URL}/assets/img/productGallery/${products[id - 1].productPhotoPath}`} alt="" />
                        <div><h1 className='cardProductHeading' >{language === 'en' ? products[id - 1].productName : products[id - 1].productName_az}</h1>
                            <p className='cardProductContent' >{language === 'en' ? products[id - 1].productDetailLong : products[id - 1].productDetailLong_az}</p>
                        </div>
                    </div>

            }
            <Footer />
        </div>
    )
}

export default Product