import React, { useState } from 'react';
import './admin.css';
import AdminAfterAuth from './AdminAfterAuth/AdminAfterAuth';
import api from '../../Services/api';

function Admin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/api/login', { username, password });

      if (response.status === 200) {
        authenticationSuccess();
      } else {
        console.log('Error occurred during calling backend:', response);
        setError('Invalid username or password');
      }

    } catch (error) {
      console.error('Error occurred during calling backend:', error);
      setError('Invalid username or password');
    }
  };

  const authenticationSuccess = () => {
    setIsLoggedIn(true);
    setError('');
  };

  return (
    <div id='admin'>
      {!isLoggedIn && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username:</label>
          <input type="text" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <label htmlFor="password">Password:</label>
          <input type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Enter</button>
        </form>
      )}

      {isLoggedIn && <AdminAfterAuth />}

      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default Admin;
