import React, { useEffect } from 'react'
import './services.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer';
import ServicesContent from './ServicesContent';

function Services(props) {

  useEffect(() => {
    const activeElement = document.getElementsByClassName('active')[0];
    activeElement.classList.remove('active');
    document.getElementById('servicesnavItem').classList.add('active');
  })

  return (
    <div id='services' className='services' >
      <Header props={props} />
      <ServicesContent />
      <Footer />
    </div>
  )
}

export default Services