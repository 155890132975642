import React, { useState } from 'react';
import ChangeContactDetails from './Changes/ChangeContactDetails'; // Değişikliklerin yapıldığı bileşenlerin olduğu yer
import ChangeSliderPhoto from './Changes/ChangeSliderPhoto';
import ChangeServices from './Changes/ChangeServices';
import ChangeProducts from './Changes/ChangeProducts';

function AdminAfterAuth() {
  const [selectedOption, setSelectedOption] = useState('');
  
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="admin-after-auth">
      <button className='selectOption' onClick={() => handleOptionChange('changeContactDetails')}>
        Change Contact Details
      </button>
      <button className='selectOption' onClick={() => handleOptionChange('changeSliderPhoto')}>
        Change Slider Photo
      </button>
      <button className='selectOption' onClick={() => handleOptionChange('changeServices')}>
        Change Services
      </button>
      <button className='selectOption' onClick={() => handleOptionChange('changeProducts')}>
        Change Products
      </button>
      {selectedOption === 'changeContactDetails' && <ChangeContactDetails />}
      {selectedOption === 'changeSliderPhoto' && <ChangeSliderPhoto />}
      {selectedOption === 'changeServices' && <ChangeServices />}
      {selectedOption === 'changeProducts' && <ChangeProducts />}
    </div>
  );
}

export default AdminAfterAuth;
