import React, { useState, useEffect } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './container.css'
import Homepage from './HomePage/Homepage'
import Products from './Products/Products'
import Services from './Services/Services'
import About from './Aboutus/Aboutus'
import Contact from './Contact/Contact'
import Admin from '../Admin/Admin';
import Product from './Products/Product/Product';


function Container() {
    let [lang, setLang] = useState();
    
    useEffect(() => {
        if(localStorage.getItem('language') === undefined){
            if (navigator.language.toLocaleLowerCase().includes('az')) {
                setLang('az');
                console.log(navigator.language.toLocaleLowerCase());
                console.log(lang);
                localStorage.setItem('language','az');
            }
            else {
                setLang('en');
                localStorage.setItem('language','en');
            }
        }
        else setLang(localStorage.getItem('language'));
    },[lang]);

    return (
        <div className='container' id='container' >
            <Router>
                <Routes>
                    <Route exact path="/" element={<Homepage lang={lang} setLang={setLang} />} />
                    <Route exact path="/products" element={<Products lang={lang} />} />
                    <Route path='/products/:id' element={<Product lang={lang} />}/>
                    <Route path="/services" element={<Services lang={lang} />} />
                    <Route path="/about" element={<About lang={lang} />} />
                    <Route path="/contact" element={<Contact lang={lang} />} />
                    <Route path="/admin" element={<Admin lang={lang} />} />
                </Routes>
            </Router>
        </div>
    )
}

export default Container