import React, { useEffect, useState } from 'react'
import api from '../../../Services/api'

function ServicesContent() {
  let [language] = useState(localStorage.getItem('language'));
  const [services, setServices] = useState({});
  const [servicesCount, setServicesCount] = useState();

  useEffect(() => {
    const fetchServicesCount = async () => {
      try {
        const response = await api.get('/api/getServices'); // API endpoint
        setServicesCount(response.data.length);
        setServices(response.data);
      } catch (error) {
        console.error('Error occured calling API', error);
      }
    };

    fetchServicesCount();
  }, [servicesCount]);


  return (
    <div className='servicescontent' >
      <h1 id='servicescontentHeading' className="componentHeading">{language === 'en' ? 'Services' : 'Xidmətlər'}</h1>
      <div id='servicesContainer' className='servicesContainer' >
        {Object.values(services).map((card) => (
          <div key={card.serviceId} className="cardItem">
            <h1 className='cardHeading' >{language === 'en' ? card.serviceHeading : card.serviceHeading_az}</h1>
            <p className='cardContent' >{language === 'en' ? card.serviceContent : card.serviceContent_az}</p>
          </div>
        ))}
      </div>

    </div>
  )
}

export default ServicesContent