import { useEffect, useState } from 'react';
import './header.css'
import { Link, useNavigate } from 'react-router-dom'

function Header() {

    const navigate = useNavigate();

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            // Enter tuşuna basıldığında /products bileşenine yönlendirme yap
            navigate('/products');
        }
    }



    let [language] = useState(localStorage.getItem('language'));

    useEffect(() => {
        const selectElement = document.getElementById('language');

        selectElement.addEventListener('change', (event) => {
            const selectedValue = event.target.value;
            localStorage.setItem('language', selectedValue);
            window.top.location.reload(true);
        });
    }, [])

    return (
        <div className='header' id='header' >
            <div id="firstHeaderContainer">
                <Link to="/" >
                    <img className='logo'
                        src={`${process.env.PUBLIC_URL}/assets/img/logo2.png`}
                        alt="logo"
                    />
                </Link>
            </div>
            <div id="secondHeaderContainer">
                <div className="nav">
                    <Link id='homepagenavItem' className='navItem active' to="/" >{language === 'en' ? 'Home' : 'Əsas Səhifə'}</Link>
                    <Link id='productsnavItem' className='navItem' to="/products" >{language === 'en' ? 'Products' : 'Məhsullar'}</Link>
                    <Link id='servicesnavItem' className='navItem' to="/services" >{language === 'en' ? 'Services' : 'Xidmətlər'}</Link>
                    <Link id='aboutnavItem' className='navItem' to="/about" >{language === 'en' ? 'About Us' : 'Haqqımızda'}</Link>
                    <Link id='contactnavItem' className='navItem' to="/contact" >{language === 'en' ? 'Contact' : 'Əlaqə'}</Link>
                </div>
            </div>
            <div id="thirdHeaderContainer" >
                <div className="languageContainer">
                    <select className='languageSelect' name="language" id="language">
                        <option value={language === 'en' ? 'en' : 'az'}>{language === 'en' ? 'Eng' : 'Aze'}</option>
                        <option value={language === 'en' ? 'az' : 'en'}>{language === 'en' ? 'Aze' : 'Eng'}</option>
                    </select>
                </div>
                <div className="searchContainer">
                    <form action="">
                        <input required onKeyPress={handleKeypress} placeholder={language === 'en' ? 'Search' : 'Axtarış'} type="search" name="searchInput" id="searchInput" />
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Header