import React, { useState, useEffect } from 'react';
import api from '../../../../Services/api';

function ChangeProduct() {
    const [newProduct, setNewProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [productPhoto, setProductPhoto] = useState(null); // State for product photo
    const [selectedProductName, setSelectedProductName] = useState(''); // State for selected product name
  
    // Ürünler yüklendiğinde çağrılır
    useEffect(() => {
      fetchProducts();
    }, []);
  
    // Ürünleri getiren fonksiyon
    const fetchProducts = async () => {
      try {
        const response = await api.get('/api/getProducts');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    // Yeni ürün eklemek için kullanılan fonksiyon
    const handleProductAdd = async () => {
      try {
        // Gerekli tüm alanlar doldurulduğundan emin ol
        if (!newProduct.productName || !newProduct.productName_az || !newProduct.productDetailShort || !newProduct.productDetailShort_az || !newProduct.productDetailLong || !newProduct.productDetailLong_az || !productPhoto) {
          alert('Please fill out all fields');
          return;
        }
  
        // Son fotoğraf adından rakamı al ve 1 artır
        const lastPhotoPath = products.length > 0 ? products[products.length - 1].productPhotoPath : 'image 0.jpg';
        const lastNumber = parseInt(lastPhotoPath.match(/\d+/)[0]);
        const nextNumber = lastNumber + 1;
  
        // FormData oluştur ve ürün bilgilerini ve fotoğrafı ekle
        const formData = new FormData();
        formData.append('productName', newProduct.productName);
        formData.append('productName_az', newProduct.productName_az);
        formData.append('productDetailShort', newProduct.productDetailShort);
        formData.append('productDetailShort_az', newProduct.productDetailShort_az);
        formData.append('productDetailLong', newProduct.productDetailLong);
        formData.append('productDetailLong_az', newProduct.productDetailLong_az);
        formData.append('productPhoto', productPhoto);
        formData.append('productId', nextNumber); // productId'i ekleyin
      
        // Yeni ürünü backend'e gönder
        await api.post('/api/addProduct', formData);
        alert('Product added successfully');
        setNewProduct({});
        setProductPhoto(null); // Clear product photo
        fetchProducts(); // Ürünleri güncelle
      } catch (error) {
        console.error('Error adding product:', error);
        alert('Error adding product');
      }
    };
  
    // Fotoğraf seçildiğinde çağrılır
    const handlePhotoChange = (e) => {
      const file = e.target.files[0];
      setProductPhoto(file);
    };

    // Ürünü silmek için kullanılan fonksiyon
    const handleProductDelete = async () => {
      try {
        const selectedProduct = products.find(product => product.productName === selectedProductName);
        if (!selectedProduct) {
          alert('Please select a product to delete');
          return;
        }
        await api.post('/api/deleteProduct', { productId: selectedProduct.productId });
        alert('Product deleted successfully');
        fetchProducts(); // Ürünleri güncelle
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Error deleting product');
      }
    };

    // Dropdown'da ürün seçildiğinde çağrılır
    const handleProductSelect = (e) => {
      setSelectedProductName(e.target.value);
    };
  
    return (
      <div className='changeProduct' >
        <h2>Change Products</h2>
        
        {/* Ürün ekleme formu */}
        <div>
          <label htmlFor="productName">Product Name:</label>
          <input
            type="text"
            id="productName"
            value={newProduct.productName || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productName: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="productName_az">Product Name (Azerbaijani):</label>
          <input
            type="text"
            id="productName_az"
            value={newProduct.productName_az || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productName_az: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="productDetailShort">Product Detail Short:</label>
          <input
            type="text"
            id="productDetailShort"
            value={newProduct.productDetailShort || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productDetailShort: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="productDetailShort_az">Product Detail Short (Azerbaijani):</label>
          <input
            type="text"
            id="productDetailShort_az"
            value={newProduct.productDetailShort_az || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productDetailShort_az: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="productDetailLong">Product Detail Long:</label>
          <textarea
            id="productDetailLong"
            value={newProduct.productDetailLong || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productDetailLong: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="productDetailLong_az">Product Detail Long (Azerbaijani):</label>
          <textarea
            id="productDetailLong_az"
            value={newProduct.productDetailLong_az || ''}
            onChange={(e) => setNewProduct({ ...newProduct, productDetailLong_az: e.target.value })}
          />
        </div>
        {/* Fotoğraf seçim input alanı */}
        <div>
          <label htmlFor="productPhoto">Product Photo:</label>
          <input
            type="file"
            id="productPhoto"
            onChange={handlePhotoChange}
          />
        </div>
        {/* Düğme */}
        <button onClick={handleProductAdd}>Add Product</button>

        {/* Dropdown */}
        <div>
          <label htmlFor="productName">Select Product:</label>
          <select id="productName" value={selectedProductName} onChange={handleProductSelect}>
            <option value="">Select...</option>
            {products.map(product => (
              <option key={product.productId} value={product.productName}>{product.productName}</option>
            ))}
          </select>
        </div>
        <button onClick={handleProductDelete}>Delete Product</button>
      </div>
    );
}

export default ChangeProduct;
