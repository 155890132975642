import React, { useEffect } from 'react'
import './aboutus.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutUsContent from './AboutUsContent'

function Aboutus(props) {
  useEffect(()=>{
    const activeElement = document.getElementsByClassName('active')[0];
    activeElement.classList.remove('active');
    document.getElementById('aboutnavItem').classList.add('active');
  })
  return (
    <div id='aboutus' className='aboutus' >
      <Header props={props} />
      <AboutUsContent/>
      <Footer/>
      </div>
  )
}

export default Aboutus