import React, { useEffect, useState } from 'react'
import './contact.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import api from '../../../Services/api'

function Contact(props) {

  const [contactDataFromBackend, setContactDataFromBackend] = useState({});

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await api.get('/api/getCustomerDetails'); // API endpoint
        setContactDataFromBackend(response.data);
      } catch (error) {
        console.error('Error occured calling API', error);
      }
    };

    fetchContactData();
  }, []);

  let [language] = useState(localStorage.getItem('language'));

  useEffect(() => {
    const activeElement = document.getElementsByClassName('active')[0];
    activeElement.classList.remove('active');
    document.getElementById('contactnavItem').classList.add('active');
  })
  return (
    <div id='contact' className='contact' >
      <Header props={props} />
      <div className="contactContainer">
        <div className="contactContainerLeftSide">
          <h1 className='contactHeading' >{language === 'en' ? 'Contact' : 'Əlaqə'}</h1>
          <div className="contactInfo">
            <div className="phoneInfoContact">
              <img className='phoneImageContact' src={`${process.env.PUBLIC_URL}/assets/img/phoneImageBlack.svg`} alt="phoneNumberDetail" />
              <p>{contactDataFromBackend.phoneNumber}</p>
            </div>
            <div className="emailInfoContact emailInfoContactMargin">
              <img className='emailImageContact' src={`${process.env.PUBLIC_URL}/assets/img/emailImageBlack.svg`} alt="email" />
              <p>{contactDataFromBackend.email}</p>
            </div>
            <div className="locationInfoContact">
              <img className='locationImageContact emailInfoContactMargin' src={`${process.env.PUBLIC_URL}/assets/img/locationImageBlack.svg`} alt="location" />
              <p>{language === 'en' ? contactDataFromBackend.address : contactDataFromBackend.address_az}</p>
            </div>
          </div>
          <div className="contactSocialMedia">
            <a rel="noreferrer" target='_blank' href="https://www.instagram.com/"><img className='socialmedialogoContact' src={`${process.env.PUBLIC_URL}/assets/img/instagramlogoGray.svg`} alt="Instagram" /></a>
            <a rel="noreferrer" target='_blank' href="https://web.whatsapp.com/"><img className='socialmedialogoContact' src={`${process.env.PUBLIC_URL}/assets/img/whatsapplogoGray.svg`} alt="Whatsapp" /></a>
          </div>
        </div>
        
      </div>
      <Footer />
    </div>
  )
}

export default Contact