import React, { useEffect, useState } from 'react'
import './products.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer';
import api from '../../../Services/api'
import { Link } from 'react-router-dom';

function Products(props) {

  const [products,setProducts] = useState();

  let [language] = useState(localStorage.getItem('language'));


  useEffect(() => {
    const activeElement = document.getElementsByClassName('active')[0];
    activeElement.classList.remove('active');
    document.getElementById('productsnavItem').classList.add('active');
    const fetchProducts = async () => {
      try {
        const response = await api.get('/api/getProducts'); // API endpoint
        setProducts(response.data);
      } catch (error) {
        console.error('Error occured calling API', error);
      }
    };

    fetchProducts();
  },[]);

  return (
    <div id='products' className='products' >
      <Header props={props} />
      <div className="productsContainer">
        <h1 className="componentHeading">
          {language === 'en' ? 'Products' : 'Məhsullar'}
        </h1>

        <div className="productsContent">
        {
        (products === undefined)?'':
        Object.values(products).reverse().map((card) => (
          
          <div id={card.productId} key={card.productId} className="cardProductItem" >
            <Link className='cardPhotoLink' to={`/products/${card.productId}`}><img className='cardPhoto' src={`${process.env.PUBLIC_URL}/assets/img/productGallery/${card.productPhotoPath}`} alt="Product" /></Link>
            <h1 className='cardHeading' >{language === 'en' ? card.productName : card.productName_az}</h1>
            <p className='cardContent' >{language === 'en' ? card.productDetailShort : card.productDetailShort_az}</p>
          </div>
          
        ))}
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Products