import React, { useState } from 'react'

function AboutUsContent() {

    let [language] = useState(localStorage.getItem('language'));

    return (
        <div className='aboutuscontent' >
            <h1 className="componentHeading">{language === 'en' ? 'About Us' : 'Haqqımızda'}</h1>
            <div className="aboutusData">
                <img
                    className='rectangle'
                    src={`${process.env.PUBLIC_URL}/assets/img/Rectangle.jpg`}
                    alt="photoaboutUs"
                />
                <div className="aboutusDataContainer">
                    <h1 className='aboutusDataContainerHeading'>{language === 'en' ? 'About Baku Polad Bakhtar Company' : 'Bakı Polad Baxtar şirkəti haqqında'}</h1>
                    <p className='aboutusDataContainerParagraph' >{language === 'en' ? 
                    'Established in 2022, Baku Polad Bakhtar Industrial and Commercial Group emerged as a dynamic entity dedicated to contributing significantly to the industrial advancement and economic prosperity of Azerbaijan. With a steadfast commitment to excellence, our organization has steadily expanded its operations over the past two years, positioning itself as a premier provider of heavy metal products and services.At Baku Polad Bakhtar, we adhere to a steadfast ethos of prioritizing customer satisfaction and service excellence. Our enduring success is attributed to our unwavering dedication to delivering unparalleled quality coupled with competitive pricing strategies. By consistently upholding the highest standards of professionalism and integrity, we have fostered enduring relationships with our clientele while continuously striving for innovation and improvement in all facets of our operations.' :
                    '2022-ci ildə yaradılan Bakı Polad Baxtar Sənaye və Kommersiya Qrupu Azərbaycanın sənaye tərəqqisinə və iqtisadi rifahına əhəmiyyətli töhfə verməyə sadiq dinamik bir qurum kimi meydana çıxdı. Mükəmməlliyə davamlı sadiqliyi ilə təşkilatımız son iki il ərzində fəaliyyətini davamlı olaraq genişləndirərək, özünü ağır metal məhsulları və xidmətlərinin əsas təchizatçısı kimi yerləşdirmişdir.Baku Polad Bakhtar-da biz müştəri məmnuniyyətini və xidmət mükəmməlliyini prioritetləşdirmək kimi sabit bir prinsipə sadiqik. Davamlı uğurumuz rəqabətli qiymət strategiyaları ilə birlikdə misilsiz keyfiyyət təqdim etmək üçün sarsılmaz sədaqətimizlə bağlıdır. Ən yüksək peşəkarlıq və dürüstlük standartlarını ardıcıl olaraq dəstəkləməklə biz müştərilərimizlə davamlı əlaqələri gücləndirmişik, eyni zamanda əməliyyatlarımızın bütün istiqamətlərində davamlı olaraq yeniliklərə və təkmilləşdirməyə çalışırıq.'}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUsContent