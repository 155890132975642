import React, { useState, useEffect } from 'react';
import './changeServices.css';
import api from '../../../../Services/api';

function ChangeServices() {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ serviceHeading: '', serviceHeading_az: '', serviceContent: '', serviceContent_az: '' });
  const [selectedService, setSelectedService] = useState(null);
  const [updatedService, setUpdatedService] = useState({ serviceId: null ,serviceHeading: '', serviceHeading_az: '', serviceContent: '', serviceContent_az: '' });

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await api.get('/api/getServices');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const handleServiceAdd = async () => {
    try {
      if (!newService.serviceHeading || !newService.serviceHeading_az || !newService.serviceContent || !newService.serviceContent_az) {
        alert('Please fill out all fields');
        return;
      }
      
      const maxServiceId = services.reduce((maxId, service) => Math.max(maxId, service.serviceId), 0);
      const addedService = { ...newService, serviceId: parseInt(maxServiceId) + 1 };
      await api.post('/api/addService', addedService);
      fetchServices();
      setNewService({ serviceHeading: '', serviceHeading_az: '', serviceContent: '', serviceContent_az: '' });
      alert('Service added successfully');
    } catch (error) {
      console.error('Error adding service:', error);
    }
  };
  
  
  const handleServiceDelete = async () => {
    try {
      if (!selectedService) {
        alert('Please select a service to delete');
        return;
      }
  
      const response = await api.post('/api/deleteService', { serviceId: selectedService.serviceId });
      if (response.status === 200) {
        alert('Service deleted successfully');
        fetchServices();
        setSelectedService(null);
      } else {
        alert('Error deleting service');
      }
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };
  
  

  const handleServiceUpdate = async () => {
    try {
      if (!selectedService || !updatedService.serviceHeading || !updatedService.serviceHeading_az || !updatedService.serviceContent || !updatedService.serviceContent_az) {
        alert('Please fill out all fields');
        return;
      }
  
      const updatedServiceData = {
        serviceId: selectedService.serviceId,
        serviceHeading: updatedService.serviceHeading,
        serviceHeading_az: updatedService.serviceHeading_az,
        serviceContent: updatedService.serviceContent,
        serviceContent_az: updatedService.serviceContent_az
      };
  
      await api.put(`/api/updateService/${selectedService.serviceId}`, updatedServiceData);
      fetchServices();
      setUpdatedService({ serviceId: null, serviceHeading: '', serviceHeading_az: '', serviceContent: '', serviceContent_az: '' });
      alert('Service updated successfully');
    } catch (error) {
      console.error('Error updating service:', error);
    }
  };
  

  return (
    <div className="changeServices">
      <h2>Change Services</h2>
      <div>
        <label htmlFor="serviceHeading">Service Heading:</label>
        <input
          type="text"
          id="serviceHeading"
          value={newService.serviceHeading}
          onChange={(e) => setNewService({ ...newService, serviceHeading: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="serviceHeading_az">Service Heading (Azerbaijani):</label>
        <input
          type="text"
          id="serviceHeading_az"
          value={newService.serviceHeading_az}
          onChange={(e) => setNewService({ ...newService, serviceHeading_az: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="serviceContent">Service Content:</label>
        <textarea
          id="serviceContent"
          value={newService.serviceContent}
          onChange={(e) => setNewService({ ...newService, serviceContent: e.target.value })}
        />
      </div>
      <div>
        <label htmlFor="serviceContent_az">Service Content (Azerbaijani):</label>
        <textarea
          id="serviceContent_az"
          value={newService.serviceContent_az}
          onChange={(e) => setNewService({ ...newService, serviceContent_az: e.target.value })}
        />
      </div>
      <button onClick={handleServiceAdd}>Add Service</button>
      <hr/>
      <div>
        <label htmlFor="deleteService">Select Service to Delete:</label>
        <select id="deleteService" onChange={(e) => setSelectedService(services.find(service => service.serviceId === parseInt(e.target.value)))}>
          <option value="">Select a service</option>
          {services.map((service,number) => (
            <option key={number} value={service.serviceId}>{service.serviceHeading}</option>
          ))}
        </select>
        <button onClick={handleServiceDelete}>Delete Service</button>
      </div>
        <hr/>
      <div>
        <label htmlFor="updateService">Select Service to Update:</label>
        <select id="updateService" onChange={(e) => setSelectedService(services.find(service => service.serviceId === parseInt(e.target.value)))}>
          <option value="">Select a service</option>
          {services.map((service,number) => (
            <option key={number} value={service.serviceId}>{service.serviceHeading}</option>
          ))}
        </select>
        <div>
          <label htmlFor="updatedServiceHeading">Updated Service Heading:</label>
          <input
            type="text"
            id="updatedServiceHeading"
            value={updatedService.serviceHeading}
            onChange={(e) => setUpdatedService({ ...updatedService, serviceHeading: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="updatedServiceHeading_az">Updated Service Heading (Azerbaijani):</label>
          <input
            type="text"
            id="updatedServiceHeading_az"
            value={updatedService.serviceHeading_az}
            onChange={(e) => setUpdatedService({ ...updatedService, serviceHeading_az: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="updatedServiceContent">Updated Service Content:</label>
          <textarea
            id="updatedServiceContent"
            value={updatedService.serviceContent}
            onChange={(e) => setUpdatedService({ ...updatedService, serviceContent: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="updatedServiceContent_az">Updated Service Content (Azerbaijani):</label>
          <textarea
            id="updatedServiceContent_az"
            value={updatedService.serviceContent_az}
            onChange={(e) => setUpdatedService({ ...updatedService, serviceContent_az: e.target.value })}
          />
        </div>
        <button onClick={handleServiceUpdate}>Update Service</button>
      </div>
    </div>
  );
}

export default ChangeServices;
